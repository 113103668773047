<script>
import Datepicker from '@/components/Datepicker'
import { Mixin } from '@/mixins/util.js'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import {
  RadioGroup,
  RadioButton,
  Switch,
  Row,
  Col,
  Container,
  Header,
  Main,
  Table,
  TableColumn,
  Button,
  Pagination
} from 'element-ui'
export default {
  name: 'momoInstock',
  mixins: [Mixin],
  components: {
    Datepicker,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-main': Main,
    'el-header': Header,
    'el-pagination': Pagination,
    'el-row': Row,
    'el-col': Col,
    'el-switch': Switch,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton
  },
  data() {
    return {
      monthlyHeader: [
        {
          label: 'セット商品コード',
          excelLabel: 'セット商品コード',
          name: 'product_item_code',
          fixed: true,
          width: 140
        },
        {
          label: '商品セット名',
          excelLabel: '商品セット名',
          name: 'product_item_name',
          fixed: true,
          width: 350
        },
        {
          excelLabel: '期初庫存',
          label: '期初在庫',
          name: 'inv_begin_quantity'
        },
        {
          excelLabel: '本月入庫',
          label: '入庫',
          name: 'inv_in_quantity'
        },
        {
          excelLabel: '入庫瑕疪',
          label: '入庫時\r\n破損数',
          name: 'inv_dam_quantity'
        },
        {
          excelLabel: '銷售出庫',
          label: '出庫',
          name: 'inv_out_quantity'
        },
        // {
        //   excelLabel: '良品轉\r\n瑕疪',
        //   label: '返品',
        //   name: 'inv_defective_return'
        // },
        // {
        //   excelLabel: '瑕疪轉\r\n良品',
        //   label: '良品再入庫',
        //   name: 'inv_qualified_return'
        // },
        {
          excelLabel: '消費者返品入庫',
          label: '消費者返品入庫',
          name: 'inv_consumer_return_in'
        },
        {
          excelLabel: '期末庫存',
          label: '期末在庫\r\n(良)',
          name: 'inv_end_qualified_quantity'
        },
        {
          excelLabel: '期末庫存',
          label: '期末在庫\r\n(不良)',
          name: 'inv_end_defective_quantity'
        }
      ],
      listLen: 0,
      offset: 10,
      curPage: 1,
      type: '1',
      source: '001_000033_000_00000000',
      startDate: '2019-12-01',
      data: [],
      supProductList: [],
      typelist:{
        '1': 'セット',
        '2': '商品',
        '3': '販促品',
        '4': '同梱物',
        '5': '資材'
      }
    }
  },
  computed: {
    daliyTableHearder() {
      if (
        this.data.length === 0 ||
        this.data === undefined ||
        this.source !== '001_000033_000_00000000'
      )
        return []

      const id = `${this.typelist[this.type]}コード`
      const name = `${this.typelist[this.type]}名`
      let col = [
        { col: 'ID', width: 140, label: id, fixed: true },
        { col: 'name', width: 350, label: name, fixed: true }
      ]
      const dateList = this.data.reduce((a, c) => {
        if (c.stock) {
          c.stock.map(t => {
            const date = t.date.split(' ')
            a.add(date[0])
          })
          return a
        }
      }, new Set())
      if (dateList) {
        for (const d of Array.from(dateList)) {
          col.push({
            col: d,
            label: d,
            width: 100,
            fixed: false
          })
        }
        return col
      }
      return []
    },
    daliyTableData() {
      if (
        this.data.length === 0 ||
        this.data === undefined ||
        this.source !== '001_000033_000_00000000'
      )
        return []
      return this.data.map(item => {
        if (item.stock) {
          let d = {
            ID: item.product_id,
            name: this.Mosaic(
              this.getNameByCode(item.product_id),
              sessionStorage.getItem('session_id')
            )
          }
          item.stock.forEach(s => {
            const date = s.date.split(' ')
            d[date[0]] = s.val<0?0:s.val
          })
          return d
        }
      })
    },
    monthlyTableHearder() {
      if (this.data.length === 0 || this.source !== '001_000033_001_00000000')
        return []
      return this.monthlyHeader
    },
    monthlyTableData() {
      if (this.data.length === 0 || this.source !== '001_000033_001_00000000')
        return []

      return this.data.map(item => {
        let clone = JSON.parse(JSON.stringify(item))
        const product = this.supProductList.filter(
          p => p[2] === clone.product_item_code || p[2] === clone.product_id
        )
        clone.product_item_name = this.Mosaic(
          product[0][4],
          sessionStorage.getItem('session_id')
        )
        return clone
      })
    }
  },
  methods: {
    async exportExcelHandler(){
      this.$eventBus.$emit('loadingHandler', true)
      const data = await this.getAllDwnDataHandler()
      const workbook = new Excel.Workbook()
      for (let inv_type of ['day','month']) {
        for (let type of Object.keys(this.typelist)) {
          if(data[inv_type][type].length!==0){
            const worksheet = workbook.addWorksheet(`${inv_type==='day'?'daliy':'monthly'}_${this.typelist[type]}`)
            if(inv_type=='day'){
              worksheet.columns = Object.keys(data[inv_type][type][0]).map(col=>{  
                return {header: col, key: col, width: 15}
              })
            }else{
              worksheet.columns = this.monthlyHeader.map(d=>{
                return {
                  header:d.excelLabel,
                  key:d.name,
                  width: 15
                }
              })
            }
            worksheet.addRows(data[inv_type][type])
          }
        }
      }
      
      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, `MOMO在庫.xlsx`)
        this.$eventBus.$emit('loadingHandler', false)
      })
    },
    async getAllDwnDataHandler(){
      let data = {}
      for (let inv_type of ['day','month']) {
        data[inv_type] = {}
        for (let type of Object.keys(this.typelist)) {
          let res = await this.getDwnDataHandler(type,inv_type)
          if(inv_type === 'day'){
            data[inv_type][type] = res.map(d=>{
              let content = {ID:d.product_id,name:this.getNameByCode(d.product_id)}
              for (const date_data of d.stock) {
                const date = date_data.date.split(' ')[0]
                content[date] = date_data.val<0?0:date_data.val
              }
              return content
            })
          }else{
            data[inv_type][type] = res.map(d=>{
              d.product_item_name = this.getNameByCode(d.product_item_code)
              return d
            })
          }
        }
      }
      // console.log(data)
      return data
    },
    async getDwnDataHandler(type,inv_type){
      const year = this.$children[0].$children[0].$children[0].year
      const month = this.$children[0].$children[0].$children[0].month
      const date = new Date(year, month, 0)
      const day = date.getDate() >9?date.getDate():`0${date.getDate()}`
      const bdd = inv_type==='day' ? '01' : day

      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = 'https://www.bamb.asia/app/hermers/pagereporter'
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: `sessionid=${s_id}&
        supplier_uid=${s_uid}&
        report_nid=93&
        byyyy=${year}&
        bmm=${month}&
        bdd=${bdd}&
        eyyyy=${year}&
        emm=${month}&
        edd=${day}&
        product_type=${type}&
        record_form=001_000033_000_00000000&
        limit_offset=0&
        inv_type=${inv_type}&
        limit_rowcount=2000`
      }
      return await fetch(url, config)
        .then(async res => await res.text())
        .then(async rep => {
          this.$eventBus.$emit('loadingHandler', false)
          const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
          return data
        })
    },
    getDataHandler() {
      this.getSupInstockLen()
      this.getSupInstock()
    },
    getNameByCode(code) {
      return this.supProductList.reduce((a, c) => {
        if (c[2] === code) a = c[4]
        return a
      }, '')
    },
    async getSupInstockLen() {
      this.listLen = 0
      const year = this.$children[0].$children[0].$children[0].year
      const month = this.$children[0].$children[0].$children[0].month
      const date = new Date(year, month, 0)
      const day = date.getDate()
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = `https://www.bamb.asia/app/hermers/pagereporter`
      // const url = `http://192.168.1.240:8080/app/hermers/pagereporter`
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: this.source
      }
      if (this.source === '001_000033_000_00000000') {
        config.body = `sessionid=${s_id}&supplier_uid=${s_uid}&report_nid=93&byyyy=${year}&bmm=${month}&bdd=1&eyyyy=${year}&emm=${month}&edd=${day}&product_type=${this.type}&record_form=${this.source}&rows=0&inv_type=day&limit_rowcount=70`

        await fetch(url, config)
          .then(async res => await res.text())
          .then(async rep => {
            const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
            this.listLen = data.count * 1
            this.isLoading = false
          })
      }
    },
    async getSupInstock() {
      const year = this.$children[0].$children[0].$children[0].year
      const month = this.$children[0].$children[0].$children[0].month
      const date = new Date(year, month, 0)
      const day = date.getDate() >9?date.getDate():`0${date.getDate()}`
      const limit = (this.curPage - 1) * this.offset
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = 'https://www.bamb.asia/app/hermers/pagereporter'
      // const url = `http://192.168.1.240:8080/app/hermers/pagereporter`
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: ''
      }
      if (this.source == '001_000033_000_00000000') {
        let count = 10
        if (this.type !== '1') count = 120
        config.body = `sessionid=${s_id}&supplier_uid=${s_uid}&report_nid=93&byyyy=${year}&bmm=${month}&bdd=01&eyyyy=${year}&emm=${month}&edd=${day}&product_type=${this.type}&record_form=${this.source}&limit_offset=${limit}&inv_type=day&limit_rowcount=${count}`
      } else
        config.body = `sessionid=${s_id}&supplier_uid=${s_uid}&report_nid=93&byyyy=${year}&bmm=${month}&bdd=${day}&eyyyy=${year}&emm=${month}&edd=${day}&product_type=${this.type}&record_form=001_000033_000_00000000&limit_offset=${limit}&inv_type=month&limit_rowcount=120`
      this.$eventBus.$emit('loadingHandler', true)
      await fetch(url, config)
        .then(async res => await res.text())
        .then(async rep => {
          this.$eventBus.$emit('loadingHandler', false)
          const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (data.records) this.data = data.records
          else this.data = data
        })
    },
    async getSupProductList() {
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = `https://www.bamb.asia/app/hermers/allProductList`
      // const url = `http://192.168.1.240:8080/app/hermers/supplierAllProducts`
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: `sessionid=${s_id}&supplier_uid=${s_uid}`
      }
      if (s_uid == '2' || s_uid == '99') {
        for (const limit of [0, 500, 1000]) {
          config.body = `sessionid=${s_id}&supplier_uid=${s_uid}&limit_begin=${limit}&limit_count=500`
          await fetch(url, config)
            .then(async res => await res.text())
            .then(async rep => {
              const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
              if (data.records.length != 0)
                this.supProductList = [...this.supProductList, ...data.records]
            })
        }
      } else {
        await fetch(url, config)
          .then(async res => await res.text())
          .then(async rep => {
            const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
            this.supProductList = data.records
          })
      }
    }
  },
  updated() {
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.$refs.table.doLayout()
      // let total
      // const header = document.querySelector('.el-table__fixed-header-wrapper')
      // const body = document.querySelector('.el-table__fixed-body-wrapper')
      // if (header) {
      //   const headerStyle = window.getComputedStyle(header, null)
      //   total = headerStyle['height'].split('px')[0] * 1
      // }
      // if (body) {
      //   const bodyStyle = window.getComputedStyle(body, null)
      //   total += bodyStyle['height'].split('px')[0] * 1
      // }
      // const table = document.querySelector('.el-table__fixed')
      // if (table) table.style.height = `${total}px`
    }, 0)
  },
  mounted() {
    // console.clear()
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
    this.getSupInstockLen()
    this.getSupInstock()
    this.getSupProductList()
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
